<template>
  <p>Redirecting...</p>
</template>

<script>
const BaseService = require("@/services/BaseService");
export default {
  name: "CrmRedirect",
  props: ["crmId"],
  mounted: function () {
    BaseService.get(
      `customer-crm-connections/${this.crmId}/customer-id`,
      {},
      (response) => {
        const body = response.data;
        const customerId = body.customerId;

          this.$router.push({
            name: 'backend.customers.view',
            params: { id: customerId }
          })
      },
      () => {}
    );
  },
};
</script>
